import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Swal from 'sweetalert2';
import { read, utils } from 'xlsx';
import axios from 'axios';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function InputFileUpload({getList}) {
    const handleChange = (files)=>{
        console.log(files)
        // setLoading(true);
        const file = files[0];
        console.log(file,'file')
        if(file){
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                const fileName = file.name;
                const fileExtension = fileName.split('.').pop();
                // console.log(fileExtension);
                // return false;
                if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    if (sheets.length) {
                        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                        console.log(rows,'row');
                        
                        axios.post(`api/users/multiplecreate`, {users: rows}).then(res => {
                            if(res.data.success){
                                Swal.fire(res.data.message, '', 'success').then(result => {
                                    getList();  
                                })                
                            } 
                            else{
                                Swal.fire(res.data.message, '', 'error')
                            }
                        }).catch((error) => {
                            Swal.fire(error.response.data.message, '', 'error')
                        });
                    
                    }
                } else {
                    Swal.fire('Must be Upload excel file', '', 'error')
                }
            }
            reader.readAsArrayBuffer(file);
        }
    
    }
    return (
        <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<FileUploadIcon />}
            className='btn btn-primary mx-3'
            style={{height: "40px", fontSize: "1.15rem", textTransform: "capitalize"}}
        >
            Upload file
            <VisuallyHiddenInput
                type="file"
                onChange={(event) => handleChange(event.target.files)}
            />
        </Button>
    );
}